import { Model } from "../lib";
import { computed } from "mobx";
import moment from "moment-timezone";
export default class Invoice extends Model {
  urlRoot = "/invoices";
  afip_url = this._store.appStore.afip_url;
  sn_cuit = this._store.appStore.sn_cuit;

  @computed
  get summary() {
    const results = [];
    const shipmentCharges = {
      name: "Envío de pedidos",
      price: 0,
      quantity: 0,
    };
    this.details.forEach((detail) => {
      if (detail.name.includes("Envío de pedidos")) {
        shipmentCharges.price =
          shipmentCharges.price + parseFloat(detail.price);
        shipmentCharges.quantity =
          shipmentCharges.quantity + parseInt(detail.quantity);
      } else {
        results.push(detail);
      }
    });
    results.push(shipmentCharges);
    return results;
  }

  @computed
  get taxes() {
    const results = [];
    if (!this.tax_details) return null;
    this.tax_details.forEach((tax) => {
      if (tax.reason.includes("10,5") && tax.amount > 0) {
        results.push({
          amount: tax.amount,
          label: "IVA 10.5%",
          description: `10.5% sobre $ ${this.amount}`,
        });
      }
      if (tax.reason.includes("21") && tax.amount > 0) {
        results.push({
          amount: tax.amount,
          label: "IVA 21%",
          description: `21.0% sobre $ ${this.amount}`,
        });
      }
    });
    return results;
  }

  @computed
  get preparedOrders() {
    const results = [];
    this.details.forEach((detail) => {
      if (detail.charge_type_id === 11) {
        results.push(detail);
      }
    });
    return results.length > 0 ? results : null;
  }

  @computed
  get invoiceType() {
    if (!this.type) return -1;
    const invoiceType = this.type.split("-")[0];
    if (
      !invoiceType ||
      invoiceType === "FCV" ||
      invoiceType === "FVE" ||
      invoiceType === "FV" ||
      invoiceType === "FCE201"
    ) {
      return 0;
    }

    if (invoiceType === "NCV") {
      return 1;
    }

    return 2;
  }

  @computed
  get invoiceTypeString() {
    const type = this.invoiceType;

    if (type === 0) {
      return "Factura";
    } else if (type === 1) {
      return "Nota de crédito";
    } else if (type === -1) {
      return "N/A";
    } else {
      return `Comprobante ${this.type}`;
    }
  }

  verifyDigits(barcode) {
    let even_sum = 0;
    let odd_sum = 0;
    const arr = barcode.split("");

    arr.forEach((number, index) => {
      if (index % 2 === 0) {
        even_sum = even_sum + parseInt(arr[index]);
      } else {
        odd_sum = odd_sum + parseInt(arr[index]);
      }
    });
    var aux = (even_sum * 3 + odd_sum) % 10;
    return aux === 0 ? 0 : 10 - aux;
  }

  @computed
  get barcode() {
    const expireDate = this.fiscal_expires_at
      ? moment(this.fiscal_expires_at).format("L").split("/")
      : null;
    if (
      !this.fiscal_impositive_type ||
      !this.invoice_reference ||
      !this.fiscal_id ||
      !this.fiscal_expires_at
    )
      return "";

    let code = `30714736724${this.fiscal_impositive_type}${
      this.invoice_reference.split("-")[1]
    }${this.fiscal_id}${expireDate[2]}${expireDate[1]}${expireDate[0]}`;

    return `${code}${this.verifyDigits(code)}`;
  }

  normalizeDocType(str) {
    return str.replace(/[^a-zA-Z ]/g, "").toLowerCase();
  }

  validateIntValue(value, digitsNum) {
    const regex = new RegExp("^[0-9]{1," + digitsNum.toString() + "}$", "g");
    return !isNaN(value) && regex.test(value);
  }

  validateDecimalValue(value) {
    const pattern = /^\d+(\.\d{0,2})?$/;
    return !isNaN(value) && pattern.test(value);
  }

  @computed
  get qrcode() {
    const tipoDocMapping = { cuit: 80, cuil: 86, dni: 96 };

    const ptoVta = parseInt(this.invoice_reference.split("-")[1], 10);
    const tipoCmp = parseInt(this.fiscal_impositive_type, 10);
    const nroCmp = parseInt(this.invoice_reference.split("-")[2], 10);
    const importe = parseFloat(this.total_amount);
    const tipoDocRec = tipoDocMapping[this.normalizeDocType(this.doc_type)];
    const nroDocRec = parseInt(this.doc_number.split("-").join(""), 10);
    const codAut = parseInt(this.fiscal_id, 10);
    const cuit = parseInt(this.sn_cuit, 10);

    if (
      this.validateIntValue(ptoVta, 5) &&
      this.validateIntValue(tipoCmp, 3) &&
      this.validateIntValue(nroCmp, 8) &&
      this.validateDecimalValue(importe) &&
      this.validateIntValue(tipoDocRec, 2) &&
      this.validateIntValue(nroDocRec, 20) &&
      this.validateIntValue(codAut, 14)
    ) {
      let textObj = {
        ver: 1,
        fecha: moment(this.issue_date).format("YYYY-MM-DD"),
        cuit,
        ptoVta,
        tipoCmp,
        nroCmp,
        importe,
        moneda: "PES",
        ctz: 1,
        tipoDocRec,
        nroDocRec,
        tipoCodAut: "E",
        codAut,
      };
      return encodeURI(`${this.afip_url}${btoa(JSON.stringify(textObj))}`);
    }

    return null;
  }

  @computed
  get reference() {
    return this.invoice_reference.split("-")[1];
  }

  isInvoice() {
    return true;
  }

  isCharge() {
    return false;
  }
}
