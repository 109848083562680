import React from "react";
import { PhoneInputV2 } from "shipnow-mercurio";
import { useField } from "formik";

export const PhoneInputFormik = (props) => {
  const [field, meta, helpers] = useField(props);
  return (
    <PhoneInputV2
      notFormik={false}
      errorMsg={meta.touched ? meta.error : ""}
      defaultValue={meta.value}
      {...props}
      {...field}
      onBlur={(e) => {
        field.onBlur(e);
        props.onBlur && props.onBlur(e);
      }}
      onChange={(e) => {
        helpers.setValue(e);
        props.onChange &&
          props.onChange({ target: { value: e, name: field.name } });
      }}
    />
  );
};
