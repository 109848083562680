import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import { useStore } from "../../../hooks/useStore";

import { AccountSelectItem } from "./AccountSelectItem/AccountSelectItem";

import { SelectV2, SelectOption } from "shipnow-mercurio";

const AccountSelect = observer((props) => {
  const { id, onChange, value, label, helpText } = props;

  const store = useStore();

  const handleChange = (sender, value) => {
    onChange && onChange(value);
  };

  return (
    <SelectV2
      id={id}
      label={label}
      helpText={helpText}
      onChange={onChange}
      maxHeightOptions="417px"
      maxWidth="400px"
      value={value}
      searchInput
      options={(q) => {
        return new Promise((resolve) => {
          store.accounts
            .search({
              q: q ? q : (value && value.company_name) || "",
              not_in_list: 1,
              sort: "active",
              order: "desc",
            })
            .andThen((results) => {
              resolve(
                results.toArray().map((account) => (
                  <SelectOption
                    display={account.company_name}
                    key={account.id}
                    onChange={handleChange}
                    value={account}
                  >
                    <AccountSelectItem account={account} />
                  </SelectOption>
                ))
              );
            });
        });
      }}
    />
  );
});

AccountSelect.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object,
  label: PropTypes.string,
  helpText: PropTypes.string,
};

AccountSelect.defaultProps = {
  id: null,
  onChange: null,
  value: null,
  label: "Cuenta",
  helpText: "Selecciona a quién aplicarle la acción.",
};

export default AccountSelect;
