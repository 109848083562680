import React, { lazy, useEffect, Suspense, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { observer } from "mobx-react";

import { ThemeProvider } from "react-jss";
import {
  Themes,
  Loader,
  NotificationProvider,
  ThemeProviderMUI,
} from "shipnow-mercurio";

import AppStore from "./stores/AppStore";
import AppContext from "./AppContext";

import useHotjar from "react-use-hotjar";
import {
  ToastProvider,
  DefaultToastContainer,
} from "react-toast-notifications";

import MediaQuery from "react-responsive";
import { retry } from "./lib/helpers";
import { CacheBuster } from "./components/Common";
import "./styles/styles.css";

import moment from "moment-timezone";

import { useNotifications } from "./assets/constants/notifications";
import { SessionContextProvider } from "./_v2/modules/session/contexts/SessionContext";

const TrackerRouter = lazy(() =>
  retry(() => import("./views/Tracker/TrackerRouter"))
);

const AppRouter = lazy(() =>
  retry(() => import("./views/AppRouter/AppRouter"))
);
const AppRouterMobile = lazy(() =>
  retry(() => import("./views/AppRouter/AppRouterMobile"))
);
const SessionRouter = lazy(() =>
  retry(() => import("./_v2/modules/router/SessionRouter/SessionRouter"))
);

const customToastContainer = ({ children, ...props }) => (
  <div className="custom-toast-container">
    <DefaultToastContainer {...props} children={children} />
  </div>
);
const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.children.props?.children[0]?.key ===
    nextProps.children.props?.children[0]?.key
  );
};
const MemoizedToast = React.memo(customToastContainer, areEqual);

// Sentry config
if (window.location.hostname !== "localhost") {
  const sentry_key = "2a84253f37e74745be00e7d370a99b48";
  const project_id = "1453458";

  // eslint-disable-next-line no-undef
  Raven.config(`https://${sentry_key}@sentry.io/${project_id}`).install();
}

moment.tz.setDefault("America/Argentina/Buenos_Aires");

const App = observer(() => {
  const [appStore] = useState(() => new AppStore());

  const arrayNotifications = useNotifications(appStore);
  const { identifyHotjar } = useHotjar();

  const TOAST_CONTAINER = { ToastContainer: MemoizedToast };

  const loader = (
    <Loader
      animation="spin"
      className="fullscreen"
      label="Cargando aplicación..."
    />
  );

  useEffect(() => {
    if (appStore.loggedInUser) {
      const { id, company } = appStore.loggedInUser;
      const userData = {
        ...company.toJS(),
        utm_exclude:
          !!company.onboarding?.medium || !!company.onboarding?.placement
            ? true
            : false,
      };
      identifyHotjar(id.toString(), userData);
    }
  }, [identifyHotjar, appStore.loggedInUser]);

  return (
    <AppContext.Provider value={appStore}>
      <SessionContextProvider store={appStore}>
        <ThemeProvider theme={Themes[appStore.getCurrentTheme()]}>
          <ThemeProviderMUI>
            <CacheBuster fallback={loader}>
              <ToastProvider
                components={TOAST_CONTAINER}
                placement="bottom-right"
                autoDismissTimeout={6000}
              >
                <NotificationProvider
                  list={arrayNotifications}
                  onChangeNewsCounter={(counter) => {
                    appStore.ui.setNewsCounter(counter);
                  }}
                >
                  {appStore.isLoggingIn ? (
                    loader
                  ) : (
                    <MediaQuery minDeviceWidth={769}>
                      {(desk) => (
                        <Suspense fallback={loader}>
                          <Switch>
                            <Route
                              path="/track"
                              component={(props) => (
                                <TrackerRouter isMobile={!desk} />
                              )}
                            />
                            <Route path="/session" component={SessionRouter} />

                            <Route
                              path="/app"
                              component={desk ? AppRouter : AppRouterMobile}
                            />

                            <Redirect from="/tracker/:id" to="/track/:id" />
                            <Redirect from="/tracker" to="/track" />
                            <Redirect from="/orders/:id" to="/app/orders/:id" />
                            <Redirect from="/orders" to="/app/orders" />

                            {/* Replace this by a 404... maybe.... */}
                            <Redirect
                              to="/app"
                              component={desk ? AppRouter : AppRouterMobile}
                            />
                          </Switch>
                        </Suspense>
                      )}
                    </MediaQuery>
                  )}
                </NotificationProvider>
              </ToastProvider>
            </CacheBuster>
          </ThemeProviderMUI>
        </ThemeProvider>
      </SessionContextProvider>
    </AppContext.Provider>
  );
});

export default App;
