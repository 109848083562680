import { observable } from "mobx";
import { version as webAppVersion } from "../../package.json";

import "whatwg-fetch";
import { isEmptyObject } from "../utils/objects";

export default class RESTClient {
  apiURI = "";
  @observable token = null;

  constructor(apiURI, token) {
    this.apiURI = apiURI;
    this.token = token;
  }

  search(uriPath, filters = {}, secure = true) {
    return this.sendRequest(
      `${uriPath}${this.getFiltersUrl(filters)}`,
      "GET",
      null,
      secure
    );
  }

  get(uriPath, id = null, filters = {}) {
    return this.sendRequest(
      id
        ? `${uriPath}/${id}${this.getFiltersUrl(filters)}`
        : `${uriPath}${this.getFiltersUrl(filters)}`,
      "GET"
    );
  }

  post(uriPath, item, secure = true, filters) {
    return this.sendRequest(
      `${uriPath}${this.getFiltersUrl(filters)}`,
      "POST",
      item.toParams ? item.toParams() : item,
      secure
    );
  }

  put(uriPath, item, itemId = null, secure = true, filters = {}) {
    return this.sendRequest(
      `${uriPath}/${itemId || item.id || ""}${this.getFiltersUrl(filters)}`,
      "PUT",
      item.toParams ? item.toParams() : item,
      secure
    );
  }

  delete(uriPath, id) {
    return this.sendRequest(`${uriPath}/${id}`, "DELETE");
  }

  sendRequest(
    uriPath,
    method = "GET",
    params = null,
    secure = true,
    customHeaders = {}
  ) {
    const url = `${this.apiURI}${uriPath}`;

    let headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      pragma: "no-cache",
      "cache-control": "no-cache",
      "X-Shipnow-App": `ShipnowMercurio/${webAppVersion}`,
    };

    let request = {
      method: method,
      headers: headers,
    };

    if (secure) {
      if (this.token) {
        headers = Object.assign(
          headers,
          {
            Authorization: `Bearer ${this.token}`,
          },
          customHeaders
        );
      } else {
        return Promise.reject(new Error("Not authenticated yet."));
      }
    } else {
      headers = Object.assign(headers, customHeaders);
    }

    if (method !== "GET" && !!params) {
      request = Object.assign(request, {
        body: JSON.stringify(params),
      });
    }

    return fetch(url, request)
      .then((res) => {
        if (res.ok) {
          let headers = {};

          for (var h of res.headers.entries()) {
            headers[[h[0]][0]] = [h[1]][0];
          }
          return res
            .json()
            .then((content) => ({
              results: content.results || content,
              headers: headers,
              status: res.status,
            }))
            .catch((err) => {
              return Promise.resolve(res); // empthy response
            });
        }
        return res.json().then((content) => {
          let err = new Error(JSON.stringify(content));

          return Promise.reject(err);
        });
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  parseFilterValue(value) {
    if (value._isAMomentObject) {
      return encodeURIComponent(value.toISOString());
    }

    return encodeURIComponent(value);
  }

  getFiltersUrl(filters) {
    if (!filters || isEmptyObject(filters)) return "";

    let result = [];
    for (var key in filters) {
      if (filters[key] === undefined || filters[key] === null) {
        result.push(key);
      } else {
        result.push(`${key}=${this.parseFilterValue(filters[key])}`);
      }
    }

    return `?${result.join("&")}`;
  }
}
