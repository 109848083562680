import { Model } from "../lib";

import { computed, action, toJS, observable } from "mobx";
import { pick, startCase, trim } from "lodash";
import moment from "moment";

import { BUYER_FIELDS } from "../assets/constants/orderConsts/orderCreation";
import {
  AWAITING_PAYMENT,
  CANCELLED,
  DELIVERED,
  NEW,
  NOT_DELIVERED,
  ON_HOLD,
  READY_TO_PICK,
  READY_TO_SHIP,
  SHIPPED,
} from "../assets/constants/exchangeConsts/orderExchangeStatus";

const DEFAULT_SHIPPING_OBJ = {
  accuracy: null,
  address_line: null,
  city: null,
  country: {
    name: "Argentina",
    code: "AR",
  },
  lat: null,
  lon: null,
  post_office_id: null,
  state: "Ciudad Autónoma de Buenos Aires",
  street_name: null,
  street_number: null,
  warehouse_id: null,
  zip_code: null,
};

export default class Order extends Model {
  constructor(attributes, store) {
    let defaultAttributes = {
      account: null,
      delivery_for: null,
      external_reference: null,
      external_reference_user: null,
      sale_price: null,
      store_id: null,
      seller_id: null,
      ship_to: DEFAULT_SHIPPING_OBJ,
      ship_to_post_office: {
        id: null,
        external_reference: null,
        external_id: null,
        description: null,
        pickup_point: null,
        drop_off_point: null,
      },
      ship_from: DEFAULT_SHIPPING_OBJ,
      items: [],
      shipping_option: {
        service_type: "ship_pap",
        service_code: null,
      },
      type: "regular",
      ship_to_warehouse_id: null,
      ship_from_warehouse_id: null,
      main_order_id: null,
      label_type: null,
      labeled_at: null,
      comment: "",
      dispatch_document_url: null,
      status: NEW,
    };

    super(attributes, store, defaultAttributes);
  }

  urlRoot = "/orders";
  events = null;

  @observable pointOfSale = null;
  @observable currentPickup = null;
  @observable currentAccount = null;
  @observable currentTransfer = null;
  @observable currentShipment = null;
  @observable currentCheckIn = null;
  @observable goodsInItem = null;
  @observable orderItems = null;
  @observable relatedOrders = null;
  @observable mainOrder = null;
  @observable packages = [];

  logs = null;
  labels = null;

  statusesIcons = {
    awaiting_payment: {
      icon: "awaitingPayment",
    },
    cancelled: {
      icon: "cancelled",
    },
    delivered: {
      icon: "delivered",
    },
    new: {
      icon: "new",
    },
    not_delivered: {
      icon: "notDelivered",
      transform: "down-1",
    },
    on_hold: {
      icon: "pause",
    },
    packing_slip: {
      icon: "box",
    },
    picking_list: {
      icon: "box",
    },
    ready_to_pick: {
      icon: "boxOpen",
    },
    ready_to_ship: {
      icon: "loading",
    },
    shipped: {
      icon: "shipped",
    },
    // --- PSEUDO STATES --------------------------
    pickup_requested: {
      icon: "pickupRequested",
    },
    returned: {
      icon: "undo",
    },
  };

  requestErrors = {
    "External reference is reserved":
      "La referencia externa ingresada ya existe",
    "must be a valid account": "La cuenta es inexistente o incorrecta",
    "Order items quantity is not a number":
      "La información de la cantidad de productos no es un numérica",
    "Order items quantity must be greater than 0":
      "La cantidad del item en la orden tiene que ser mayor a 0",
    "Order items unit price is not a number":
      "La información del precio del producto no es un numérica",
    "Order zip code is invalid": "El código postal es incorrecto",
    "Order status is invalid": "El estado del pedido es inválido",
    "Ship from address can't change if have exchange_and_return mode":
      "Error debes editar la order de devolucion esta no es editable",
  };
  @action
  afterSetData() {
    if (this.store && this._store) {
      this.pointsOfSale = this._store.appStore.pointsOfSale.getFromData(
        this.store
      );
    }

    if (this.goods_in_item && this._store) {
      this.setGoodsInItem(this.goods_in_item);
    }

    if (this.shipment && this._store) {
      this.currentShipment = this._store.appStore.shipments.getFromData(
        this.shipment
      );
    }

    if (this.account && this._store) {
      this.currentAccount = this._store.appStore.accounts.getFromData(
        this.account
      );
    }

    if (this.check_in && this._store) {
      this.currentCheckIn = this._store.appStore.checkIns.getFromData(
        this.check_in
      );
    }
  }

  setGoodsInItem(goods_in_item) {
    this.goodsInItem = this._store.appStore.goodsInItems.getFromData(
      goods_in_item
    );

    if (goods_in_item.pickup) {
      this.currentTransfer = null;
      this.currentPickup = this._store.appStore.goodsIns.getFromData(
        goods_in_item.pickup
      );
    } else if (goods_in_item.transfer) {
      this.currentPickup = null;
      this.currentTransfer = this._store.appStore.goodsIns.getFromData(
        goods_in_item.transfer
      );
    }
  }

  //TODO: ver si podemos eliminar esto. Tenemos que revisar lo de ShipToAdrress.
  fetchRelatedOrders() {
    if (this.main_order_id && this._store && !this.relatedOrders) {
      this.beginUpdate();
      let relatedOrder = this._store.get(this.main_order_id);
      let that = this;
      relatedOrder.andThen((r) => {
        that.relatedOrders = [relatedOrder];
        this.endUpdate();
      });
    }
    return this;
  }

  matchTrackingNumber(value) {
    if (!this.shipment) return false;

    return (
      this.shipment.tracking_number === value ||
      this.shipment.items.some((item) => item.tracking_number === value)
    );
  }

  @computed
  get getOrderPackage() {
    let packageType = this.package_type || this.items[0].package_type;

    if (!packageType) return null;

    packageType = parseInt(packageType, 10);

    const orderPackage = this._store.appStore.ui.lists.packageTypes
      .toJS()
      .find((orderPackage) => orderPackage.key.id === packageType);

    if (!orderPackage) return null;

    return {
      ...orderPackage.key,
      description: orderPackage.value,
    };
  }

  //TODO: dejar solo uno de los dos.
  @computed
  get exchange() {
    return this.type === "exchange";
  }

  isReturn() {
    return this.type === "exchange";
  }

  get exchangeAndReturn() {
    return this.shipping_option.service_mode === "exchange_and_return";
  }

  @computed
  get reference() {
    return this.external_reference_user || this.external_reference || this.id;
  }

  @computed
  get webURI() {
    if (this.isReturn()) {
      return `/app/returns/list/${this.id}`;
    }

    return `${this._store.baseWebURI}/${this.id}`;
  }

  @computed
  get isSelected() {
    return this._store.appStore.ui.lists.selectedOrders.find(
      (item) => item.id === this.id
    );
  }

  @computed
  get sellerInfo() {
    return this.isReturn() ? this.ship_to : this.ship_from;
  }

  @computed
  get buyerInfo() {
    return this.isReturn() ? this.ship_from : this.ship_to;
  }

  @computed
  get buyerName() {
    const buyerInfo = this.buyerInfo;
    const fullName = buyerInfo?.full_name;

    if (fullName && trim(fullName) === "") {
      return buyerInfo.email &&
        buyerInfo.email.indexOf("@mail.mercadolibre") === -1
        ? buyerInfo.email.substr(0, buyerInfo.email.indexOf("@"))
        : buyerInfo.email.substr(0, buyerInfo.email.indexOf("."));
    }

    return fullName;
  }

  @computed
  get buyerEmail() {
    const buyerInfo = this.buyerInfo;

    if (buyerInfo.email && buyerInfo.email.indexOf("@mail.mercadolibre") > 0) {
      return buyerInfo.email.replace(/\..+@/gi, "@");
    }

    return buyerInfo.email ? buyerInfo.email.toLowerCase() : "";
  }

  @computed
  get cookedStatus() {
    if (
      (this.status === "new" || this.status === "on_hold") &&
      this.goods_in_item
    ) {
      return "pickup_requested";
    }

    return this.status;
  }

  @computed
  get cookedItems() {
    const items = this.items;
    if (!items) return [];

    let _items =
      items.length > 1 ? items.filter((item) => !item.cross_docking) : items;

    return _items.map((i) => {
      i["key"] = `${i.id}-${i.package_number}`;
      return i;
    });
  }

  @computed
  get icon() {
    if (!this.isManagedByShipnow() && !this.isReturn()) {
      return ["far", "paper-plane"];
    } else if (this.isReturn()) {
      return "reply";
    } else if (this.isResend()) {
      return "forward";
    } else if (this.isCrossDockable()) {
      return ["far", "paper-plane"];
    }

    return "paper-plane";
  }

  @computed
  get color() {
    let color = "secondary";

    //Envío por fuera de shipnow
    if (!this.isManagedByShipnow() || !this.isEnabled() || this.archived) {
      color = "greyLight";
    }

    //Nuevo envío, posible de enviar por shipnow
    else if (this.isCrossDockable()) {
      color = "primaryLight";
    }

    //Devolución
    else if (this.isReturn()) {
      color = "warning";
    }

    // Reenvío
    else if (this.isResend()) {
      color = "primaryDarkPressed";
    }

    return color;
  }

  @computed
  get statusText() {
    const statuses = {
      new: "Nuevo",
      awaiting_payment: "Esperando pago",
      ready_to_pick: "A preparar",
      picking_list: "Preparando",
      packing_slip: "Preparando",
      ready_to_ship: "A despachar",
      shipped: "Despachado",
      delivered: "Entregado",
      cancelled: "Cancelado",
      not_delivered: "No entregado",
      on_hold: "Detenido",
      // --- PSEUDO STATES --------------------------
      pickup_requested: "A recolectar",
    };

    return statuses[this.cookedStatus];
  }

  @computed
  get statusIcon() {
    return this.statusesIcons[this.cookedStatus];
  }

  @computed
  get statusColor() {
    if (!this.isManagedByShipnow() || !this.isEnabled() || this.archived)
      return "grey";

    const statuses = {
      awaiting_payment: "warning",
      cancelled: "error",
      delivered: "success",
      new: "warning",
      not_delivered: "error",
      on_hold: "error",
      packing_slip: "grey",
      picking_list: "grey",
      ready_to_pick: "grey",
      ready_to_ship: "grey",
      shipped: "highlight",
      // --- PSEUDO STATES --------------------------
      pickup_requested: "warning",
      returned: "success",
    };

    return statuses[this.cookedStatus];
  }

  @computed
  get shipToSummary() {
    if (!this.shipping_option) return "";

    const shipPreffix = this.isResend()
      ? "Reenvío"
      : this.isReturn()
      ? "Devolución"
      : "Envío";

    const shipment_types = {
      ship_pap: `${shipPreffix}`,
      ship_pas: `${shipPreffix} para retiro`,
      ship_sap: `${shipPreffix} de sucursal a puerta`,
      ship_sas: `${shipPreffix} de sucursal a sucursal`,
      pickup: "Retiro",
      exchange: "Devolución desde",
    };

    const shipment_codes = {
      custom_service: `${shipPreffix} por correo personal`,
      pending: `${shipPreffix} pendiente de definir`,
      to_be_agreed: `Envío a arreglar con el comprador`,
      custom_operation: "Retiro por local",
      pickup_warehouse:
        this.ship_to_warehouse?.account_id === 1
          ? "Retiro por shipnow"
          : "Retiro por depósito",
      dhl_express_worldwide: `${shipPreffix} al exterior`,
      exchange_warehouse: `Devolución a cargo del comprador`,
    };

    let result =
      shipment_codes[this.shipping_option.service_code] ||
      shipment_types[this.shipping_option.service_type];

    if (
      ["ship_pap", "ship_pas"].includes(this.shipping_option.service_type) &&
      this.shipping_option.service_code !== "to_be_agreed"
    ) {
      result = `${result} a ${this.shipToAddressRegion}`;
    }

    if (
      "exchange" === this.shipping_option.service_type &&
      this.shipping_option.service_code !== "exchange_warehouse"
    ) {
      result = `${result} ${this.shipFromAddressRegion}`;
    }

    return result;
  }

  @computed
  get deliverySummary() {
    if (!this.shipping_option) return "";

    const shipPreffix = this.isReturn() ? "Devolución" : "Envío";

    const shipment_types = {
      ship_pap: `${shipPreffix} a domicilio`,
      ship_pas: "Retira en sucursal",
      ship_sap: `${shipPreffix} desde sucursal`,
      ship_sas: "Retira en sucursal",
      pickup: "Retira en sucursal",
      exchange: "Devolución desde",
    };

    const shipment_codes = {
      pickup_store: "Retiro por depósito con envío",
      custom_service: "Envío por correo personal",
      pending: "Envío pendiente de definir",
      to_be_agreed: "Envío a arreglar con el comprador",
      custom_operation: "Retiro por local",
      pickup_warehouse:
        this.ship_to_warehouse?.account_id === 1
          ? "Retiro por shipnow"
          : "Retiro por depósito",
      dhl_express_worldwide: "Envío al exterior",
      exchange_warehouse: "Devolución a depósito",
    };

    let result =
      shipment_codes[this.shipping_option.service_code] ||
      shipment_types[this.shipping_option.service_type];

    if (
      "exchange" === this.shipping_option.service_type &&
      this.shipping_option.service_code !== "exchange_warehouse"
    ) {
      result = `${result} ${this.shipFromAddressRegion}`;
    }

    if (["ship_pas", "ship_sas"].includes(this.shipping_option.service_type)) {
      result = `${result} ${this.carrier && this.carrier.name}`;
    }

    return result;
  }

  getShippingWarehouse() {
    let warehouse;

    if (this.isReturn()) {
      warehouse = this.ship_to_warehouse;
    } else {
      warehouse = this.ship_from_warehouse;
    }

    return warehouse;
  }

  // TODO: renombar a shippingAddress
  @computed
  get shipToAddress() {
    let address;

    const addressType = this.isReturn() ? this.ship_from : this.toAddress;

    if (
      !addressType.address_line &&
      !addressType.street_name &&
      !addressType.street_number
    )
      return null;

    if (this.isReturn()) {
      address = this.ship_from.address_line
        ? `${this.ship_from.address_line}, ${this.shipFromAddressRegion}`
        : `${this.ship_from.street_name}, ${this.ship_from.street_number}, ${this.shipFromAddressRegion}`;

      if (this.ship_from.country.code !== "AR") {
        address = `${address}, ${this.ship_from.country.name}`;
      }
    } else {
      address = this.toAddress.address_line
        ? `${this.toAddress.address_line}, ${this.shipToAddressRegion}`
        : `${this.toAddress.street_name}, ${this.toAddress.street_number}, ${this.shipToAddressRegion}`;

      if (this.toAddress.country && this.toAddress.country.code !== "AR") {
        address = `${address}, ${this.toAddress.country.name}`;
      }
    }

    return address;
  }

  @computed
  get shipToAddressShort() {
    return this.ship_to.address_line
      ? this.ship_to.address_line
      : `${this.ship_to.street_name}, ${this.ship_to.street_number}`;
  }

  @computed
  get toAddress() {
    return this.shipping_option.service_code === "pickup_warehouse" &&
      this.ship_to_warehouse &&
      this.ship_to_warehouse.address
      ? this.ship_to_warehouse.address
      : this.ship_to;
  }

  @computed
  get shipToAddressRegion() {
    if (this.shipping_option.service_code === "dhl_express_worldwide") {
      return `${startCase(this.toAddress.state.toLowerCase())}, ${startCase(
        this.toAddress.country.name.toLowerCase()
      )}`;
    }

    if (this.toAddress.city === this.toAddress.state) {
      return `${
        this.toAddress.city
          ? startCase(this.toAddress.city.toLowerCase())
          : this.toAddress.state
          ? startCase(this.toAddress.state.toLowerCase())
          : ""
      }`;
    }

    return `${
      this.toAddress.city && startCase(this.toAddress.city.toLowerCase())
    }, ${
      this.toAddress.state && startCase(this.toAddress.state.toLowerCase())
    }`;
  }

  @computed
  get shipFromAddressRegion() {
    if (this.shipping_option.service_code === "dhl_express_worldwide") {
      return `${
        this.ship_from.state && startCase(this.ship_from.state.toLowerCase())
      }, ${
        this.ship_from.country &&
        startCase(this.ship_from.country.name.toLowerCase())
      }`;
    }

    if (this.ship_from.city === this.ship_from.state) {
      return `${startCase(this.ship_from.city.toLowerCase())}`;
    }

    return `${
      this.ship_from.city && startCase(this.ship_from.city.toLowerCase())
    }, ${
      this.ship_from.state && startCase(this.ship_from.state.toLowerCase())
    }`;
  }

  @computed
  get carrier() {
    const shippingServices = this._store.appStore.ui.lists.shippingServices;

    if (
      this.shipping_option.service_code &&
      shippingServices &&
      shippingServices.isOk()
    ) {
      const services = shippingServices.toArray();

      const service = services.find(
        (c) => c.code === this.shipping_option.service_code
      );

      return service ? service.carrier : null;
    }

    const carriers = this._store.appStore.ui.lists.carriers.toArray();

    return carriers.find((c) => c.code === this.shipping_option.carrier_code);
  }

  @computed
  get statusChangedAt() {
    switch (this.status) {
      case "new":
      case "awaiting_payment":
        return moment(this.timestamps.created_at);
      case this.cookedStatus:
        return moment(this.timestamps[`${this.status}_at`]);
      default:
        return this.updated_at;
    }
  }

  @computed
  get instanceKey() {
    return this.id
      ? `${this.id}-${new Date(this.updated_at).getTime()}`
      : `new-${new Date()}`;
  }

  @computed
  get label_url() {
    if (!this.shipment) return "";

    return this.shipment.label_url || "";
  }

  @computed
  get document_url() {
    if (!this.shipment) return "";

    return this.shipment.document_url || "";
  }

  @computed
  get goodsIn() {
    return this.currentPickup || this.currentTransfer;
  }

  @computed
  get source() {
    const currentUser = this._store.appStore.loggedInUser;
    const storeName = this.store ? this.store.name : "Pedidos Manuales";

    if (currentUser.isMultiAccount()) {
      return `${this.seller_name} (${storeName})`;
    }

    return storeName;
  }

  @computed
  get buyerAddressLine() {
    const customLine = `${this.buyerInfo.strea_number} ${this.buyerInfo.street_name}`;

    return this.buyerInfo.address_line || customLine;
  }

  @computed
  get shippingWarehouseId() {
    return this.isReturn()
      ? this.ship_to_warehouse_id
      : this.ship_from_warehouse_id;
  }

  generateReference() {
    const buyerName = (this.buyerInfo.name || this.buyerInfo.street_name)
      .replace(/[aeiou]/gi, "")
      .substring(0, 3)
      .toUpperCase();

    //Date in numbers
    const date = new Date();

    const intDate = moment(date).format("DDMYYYYHmmss");
    return `${buyerName}-${intDate}`;
  }

  isShippingExchange() {
    return (
      this.shipping_option && this.shipping_option.service_type === "exchange"
    );
  }

  @computed
  get isShipnowCarrier() {
    return this.shipping_option.carrier_code === "shipnow";
  }

  getWeight() {
    if (this.weight > 0) return this.weight;

    return this.recalculateWeight();
  }

  recalculateWeight() {
    let result = 0;

    this.items.forEach((item) => {
      result += item.variant_weight * item.quantity;
    });

    this.weight = result;

    return result;
  }

  inPreparation() {
    return [
      "ready_to_pick",
      "picking_list",
      "packing_slip",
      "ready_to_ship",
    ].includes(this.status);
  }

  isProcessed() {
    return (
      [
        "packing_slip",
        "ready_to_ship",
        "shipped",
        "delivered",
        "not_delivered",
      ].includes(this.status) ||
      (this.status === "on_hold" && !!this.ready_to_ship_at)
    );
  }

  isCustomOperation() {
    return (
      this.shipping_option &&
      this.shipping_option.service_code === "custom_operation"
    );
  }

  isPending() {
    return (
      this.shipping_option && this.shipping_option.service_code === "pending"
    );
  }

  inFinalStatus() {
    return ["cancelled", "not_delivered", "delivered"].includes(this.status);
  }

  isManagedByShipnow() {
    return !this.isCustomOperation();
  }

  isFlex() {
    return this.shipping_option.service_code === "shipnow_flex";
  }

  isSameDay() {
    return (
      this.shipping_option.category === "super_express" &&
      this.shipping_option.service_type === "ship_pap" &&
      this.shipping_option.service_mode === "delivery" &&
      this.shipping_option.service_code !== "shipnow_flex"
    );
  }

  isCrossDockable() {
    if (!this.items) return false;

    const CDItem = this.items.find(
      (i) => i.picking_preference === "cross_docking"
    );

    return !!CDItem;
  }

  @computed
  get isCrossDocking() {
    return (
      this.cross_docking ||
      (this.goodsInItem && this.goodsInItem.type === "pickup")
    );
  }

  @computed
  get canBeCrossDocking() {
    return (
      (this.account &&
        this.account.picking_preference_own === "cross_docking") ||
      this.isCrossDocking
    );
  }

  @computed
  get withoutShippingOption() {
    return (
      !this.shipping_option.service_type && !this.shipping_option.service_code
    );
  }

  @computed
  get hasNuntiusCarrier() {
    return ["bmba", "shipnow"].includes(this.shipping_option.carrier_code);
  }

  @computed
  get showPendingLabels() {
    return (
      this.canBeCrossDocking &&
      !this.exchange &&
      (this.shipment || this.goodsIn)
    );
  }

  isDelayed() {
    return (
      this.shipment &&
      this.status === "shipped" &&
      this.shipment.condition === "delayed"
    );
  }

  getWarnings() {
    let result = [];
    const warnings = toJS(this.warnings);
    if (warnings) {
      Object.keys(warnings).forEach(
        (key) => (result = result.concat(warnings[key]))
      );
    }

    let allowedWarings = [
      "service_pending",
      "from_zip_code_non_existent",
      "to_zip_code_non_existent",
      "drop_off_invalid",
      "pickup_point_invalid",
    ];

    if (this.goodsIn === null) allowedWarings.push("without_stock");

    result = result.filter((value) => -1 !== allowedWarings.indexOf(value));

    return result;
  }

  static warningLabel(warning) {
    const labels = {
      without_stock: "Sin stock",
      service_pending: "Sin servicio",
      from_zip_code_non_existent: "Código postal inválido",
      to_zip_code_non_existent: "Código postal inválido",
      drop_off_invalid: "Sucursal inválida",
      pickup_point_invalid: "Sucursal inválida",
    };

    return labels[warning];
  }

  getCheckInsEvents() {
    let events = [];
    if (this.currentShipment && this.currentShipment.delivered_at) {
      events.push({
        label: "Listo para ingresar",
        at: moment(this.shipment.delivered_at),
      });
    }

    if (this.currentCheckIn && this.currentCheckIn.completed_at) {
      events.push({
        label: "Ingresado",
        at: moment(this.completed_at),
      });
    }
    return events;
  }

  @action
  fetchEvents() {
    if (this.events || !this.uid) {
      return this;
    } else {
      this.beginUpdate();
      this._store.appStore.coreAPIClient
        .search("/tracking", {
          uid: this.uid,
        })
        .then(
          (res) => {
            this.setField("events", res.results.events);
            this.endUpdate();
          },
          (err) => {
            this.endUpdate();
          }
        );
    }
    return this;
  }
  setBuyersInfo(buyersInfo) {
    this.ship_to.city = buyersInfo.city;
    this.ship_to.country.name = buyersInfo.country.name;
    this.ship_to.country.code = buyersInfo.country.code;
    this.ship_to.floor = buyersInfo.floor;
    this.ship_to.lat = buyersInfo.lat;
    this.ship_to.lon = buyersInfo.lon;
    this.ship_to.state = buyersInfo.state;
    this.ship_to.street_name = buyersInfo.street_name;
    this.ship_to.street_number = buyersInfo.street_number;
    this.ship_to.unit = buyersInfo.unit;
    this.ship_to.zip_code = buyersInfo.zip_code;
  }

  // @computed
  get curatedEvents() {
    if (!this.events) return [];

    const statuses = {
      ready_to_pick: "Listo para preparar",
      packing_slip: "Preparandose",
      ready_to_ship: "Listo para despachar",
      dispatched: "Despachado",
      shipped: "Recibido por el correo",
      in_post_office: "En sucursal",
      awaiting_pickup: "Esperando recolección",
      delivered: "Entregado",
      not_delivered: "No entregado",
      visit_scheduled: "Visita a domicilio programada",
      visit_shipping: "Visita a domicilio en curso",
      visit_without_news: "Visita a domicilio sin novedades",
      visit_failed: "Visita a domicilio fallida",
      rescheduling_visit: "Reprogramando visita a domicilio",
    };

    return this.events.map((e) => ({
      status: statuses[e.status],
      code: e.status,
      timestamp: moment(e.changed_at),
    }));
  }

  @action
  fetchLabels(forceRefresh = false) {
    if ((this.labels || !this.hasLabel()) && !forceRefresh) {
      return this;
    } else if (this.shipment) {
      this.beginUpdate();
      this._store.appStore.coreAPIClient
        .search(
          `${this.urlRoot}/${this.id}/shipments/${this.shipment.id}/labels`,
          { only: "zpl" }
        )
        .then(
          (res) => {
            this.labels = res.results;

            this.endUpdate();
          },
          (err) => {
            this.endUpdate();
          }
        );
    }
    return this;
  }

  // TODO: refactor. Utilizar el account_id del ship_from_warehouse y ship_to_warehouse,
  // para compararlo con currentUser.account_id.
  isMyOrder() {
    const currentUser = this._store.appStore.loggedInUser;

    if (currentUser.isMultiAccount()) return true;

    return currentUser.warehouses.some(
      (w) =>
        w.id === this.ship_from_warehouse_id ||
        w.id === this.ship_to_warehouse_id
    );
  }

  isEnabled() {
    return !this.account || this.account.active;
  }

  isCanChangeGoodsInByStatus() {
    return (
      this.status === "new" ||
      (this.status === "on_hold" && !this.ready_to_ship_at)
    );
  }

  abilities = {
    changeGoodsIn: (loggedInUser) => {
      return (
        !this.isReturn() &&
        this.isCanChangeGoodsInByStatus() &&
        !this.isCustomOperation() &&
        !this.isPending()
      );
    },
    products_edited: (loggedInUser) => {
      if (this.isReturn()) {
        return ["new", "on_hold"].includes(this.status);
      }
      return (
        ["new", "awaiting_payment"].includes(this.status) ||
        (this.status === "on_hold" && !this.ready_to_ship_at)
      );
    },
    shipment_edited: (loggedInUser) => {
      if (this.isReturn()) {
        return ["new", "on_hold"].includes(this.status);
      } else {
        return (
          !["shipped", "delivered", "not_delivered", "cancelled"].includes(
            this.status
          ) &&
          (!this.shipment || !this.inDispatchControl()) &&
          !this.isFlex()
        );
      }
    },
    collected: (loggedInUser) =>
      this.status !== "cancelled" &&
      !this.archived &&
      (this.goodsIn === null ||
        !["completed", "picked"].includes(this.goodsIn.status)),
    cancelled: (loggedInUser) => {
      if (this.isReturn()) {
        return ["new", "on_hold"].includes(this.status);
      }

      //? Para easy_wms bloqueamos la posibilidad de cancelar pedidos en estado ready_to_ship
      if (this.ship_from_warehouse?.warehouse_system_code === "easy_wms") {
        return ![
          "shipped",
          "cancelled",
          "not_delivered",
          "delivered",
          "ready_to_ship",
        ].includes(this.status);
      }

      return (
        !["shipped", "cancelled", "not_delivered", "delivered"].includes(
          this.status
        ) &&
        (!this.shipment || !this.inDispatchControl())
      );
    },
    filter_goods_in: (loggedInUser) =>
      (this.goodsIn !== null ||
        ["completed", "cancelled"].includes(this.goodsIn.status)) &&
      this.goodsInItem.quantity_received === 0,
    added_to_pickup: (loggedInUser) => !this.archived,
    archived: (loggedInUser) => !this.archived,
    marked_as_delivered: (loggedInUser) => {
      //? el pedido ya está procesado.
      if (this.check_in?.received_at) return false;

      if (!this.isMyOrder()) return false;

      if (
        this.hasNuntiusCarrier &&
        ["ready_to_ship", "shipped"].includes(this.status) &&
        this.timestamps.ready_to_ship_at
      )
        /* Estos pedidos deberían marcarse como entregados en Nuntius */
        return false;

      return [
        "on_hold",
        "awaiting_payment",
        "ready_to_pick",
        "ready_to_ship",
        "shipped",
        "not_delivered",
      ].includes(this.status);
    },
    marked_as_not_delivered: (loggedInUser) => {
      //? el pedido ya está procesado.
      if (this.check_in?.received_at) return false;

      if (!this.isMyOrder()) return false;

      if (
        this.hasNuntiusCarrier &&
        ["ready_to_ship", "shipped"].includes(this.status) &&
        this.timestamps.ready_to_ship_at
      )
        /* Estos pedidos deberían marcarse como no entregados en Nuntius */
        return false;

      return ["shipped", "delivered"].includes(this.status);
    },
    marked_as_received: (loggedInUser) => {
      return (
        loggedInUser.isWhsOwner() &&
        !this.hasNuntiusCarrier &&
        [NEW, SHIPPED].includes(this.status)
      );
    },
    marked_as_not_received: (loggedInUser) => {
      return (
        loggedInUser.isWhsOwner() &&
        !this.hasNuntiusCarrier &&
        [NEW, SHIPPED].includes(this.status)
      );
    },
    marked_as_not_returned: (loggedInUser) => {
      if (!loggedInUser.isWhsOwner()) return false;

      if (this.account.picking_preference_own === "warehouse") {
        return ["processing", "received"].includes(this.check_in?.status);
      }

      return ["received"].includes(this.check_in?.status);
    },
    marked_as_returned: (loggedInUser) => {
      if (!loggedInUser.isWhsOwner()) return false;

      if (this.account.picking_preference_own === "warehouse") {
        return ["processing", "received"].includes(this.check_in?.status);
      }

      //? crossdocking accounts with old return group.
      return this.check_in?.status === "received" || this.hadReturnGroup;
    },
    marked_as_back_in_stock: (loggedInUser) => {
      if (!loggedInUser.isWhsOwner()) return false;

      if (this.account.picking_preference_own === "warehouse") {
        return ["processing", "received"].includes(this.check_in?.status);
      }

      return false;
    },
    marked_as_not_back_in_stock: (loggedInUser) => {
      if (!loggedInUser.isWhsOwner()) return false;

      if (this.account.picking_preference_own === "warehouse") {
        return ["processing", "received"].includes(this.check_in?.status);
      }

      return false;
    },
    marked_as_paid: (loggedInUser) => this.status === "awaiting_payment",
    paused: (loggedInUser) => {
      if (this.isReturn()) {
        return false;
      } else if (this.isMyOrder()) {
        return (
          [
            "new",
            "awaiting_payment",
            "ready_to_pick",
            "ready_to_ship",
          ].includes(this.status) &&
          (!this.shipment || !this.inDispatchControl())
        );
      } else {
        return (
          ["new", "awaiting_payment", "ready_to_pick"].includes(this.status) &&
          (!this.shipment || !this.inDispatchControl())
        );
      }
    },
    print_labels: (loggedInUser) => {
      return (
        (this.shipment || this.goodsIn) &&
        this.isToWarehouse() &&
        this.status === NEW
      );
    },
    print_processed_label: (loggedInUser) => {
      return loggedInUser.isAdmin() && this.currentCheckIn?.processed;
    },
    return_action_edited: (loggedInUser) =>
      this.isManagedByShipnow() &&
      !this.isReturn() &&
      this.status === "not_delivered" &&
      this.check_in &&
      this.canCreateResendOrder(),
    resumed: (loggedInUser) =>
      this.status === "on_hold" && (!this.exchangeAndReturn || !this.exchange),
    returned: (loggedInUser) =>
      ["shipped", "delivered"].includes(this.status) && !this.isReturn(),
    share_return_instructions: () => {
      return (
        (this.status === NEW && !this.isPAP) ||
        (this.isToAddress() && this.status === READY_TO_SHIP)
      );
    },
    dispatch_date_edited: (loggedInUser) =>
      (loggedInUser.isAdmin() || loggedInUser.canEditDispatchDate()) &&
      !this.isReturn(),
    documents_printed: (loggedInUser) =>
      this.shipping_option &&
      this.shipping_option.service_code !== "shipnow_exchange" &&
      this.shipping_option.service_code !== "exchange_warehouse" &&
      (this.shipping_option.service_mode === "exchange" ||
        this.exchangeAndReturn ||
        (this.shipping_option.service_code === "dhl_express_worldwide" &&
          this.document_url !== "")),
    dispatch_link_show: (loggedInUser) =>
      loggedInUser.isWhsOwner() && this.hasDispatch(),
    dispatch_document_generated: (loggedInUser) =>
      !this.isExchange &&
      this.isManagedByShipnow() &&
      ((this.status === NEW && !this.isPAP) ||
        (this.isToAddress() && this.status === READY_TO_SHIP)),
  };

  hasLabel() {
    return !this.isReturn() && this.isManagedByShipnow();
  }

  @computed
  get haveReturnGroup() {
    if (this.isReturn()) {
      return (
        this.account.picking_preference_own === "cross_docking" &&
        this.currentCheckIn?.processed &&
        this.check_in.action_resource_id
      );
    }

    return (
      this.account.picking_preference_own === "cross_docking" &&
      this.currentCheckIn?.action_return &&
      this.currentCheckIn.processed &&
      this.check_in.action_resource_id &&
      this.status === "not_delivered"
    );
  }

  @computed
  get hadReturnGroup() {
    return (
      this.check_in &&
      this.check_in.scheduled_action === "return" &&
      this.currentCheckIn.processed &&
      !this.check_in.action_resource_id &&
      !this.isCheckInFinalStatus()
    );
  }

  @computed
  get showReturnGroup() {
    return this.haveReturnGroup || this.hadReturnGroup;
  }

  hasTracking() {
    if (!this.uid || !this.isManagedByShipnow()) {
      return false;
    }

    if (!this.isReturn()) {
      const statuses = ["awaiting_payment", "cancelled", "new", "on_hold"];

      return !statuses.includes(this.status);
    }
    return true;
  }

  hasDispatch() {
    return (
      this.shipment &&
      this.shipment.items.length > 0 &&
      this.shipment.items[0].dispatch_id
    );
  }

  getDispatchURI() {
    return `/app/operations/dispatches/${this.shipment.items[0].dispatch_id}`;
  }

  getQuantityReceived() {
    return this.goodsInItem?.quantity_received;
  }

  storeURL() {
    if (!this.store) return null;

    const storeType = this.store.store_type;

    let url;

    if (!this.store.url || this.store.url.includes("notifications"))
      return null;

    switch (storeType) {
      case "shopify":
        url = `${this.store.url}/admin/orders?query=${this.external_reference}`;
        break;

      case "meli":
        url = `https://myaccount.mercadolibre.com.ar/sales/${this.external_reference}/detail`;
        break;

      case "tiendanube":
        url = `${this.store.url}/admin/orders/${this.external_reference}`;
        break;

      default:
        url = null;
        break;
    }

    return url
      ? url.includes("https") || url.includes("http")
        ? url
        : `http://${url}`
      : null;
  }
  @action
  patch(params) {
    return this._store.appStore.coreAPIClient
      .put(this._store.modelRoot, params)
      .then(
        (res) => {
          if (res && res["results"]) {
            this.set(res["results"]);
            this._store.items.addOrUpdateModel(this);
            this.endUpdate();
          }
        },
        (err) => {
          this.endUpdate(err);
          //throw err;
        }
      );
  }

  @action
  archive() {
    const params = {
      id: this.id,
      archived: true,
    };
    //make this a generic function
    this._store.appStore.coreAPIClient.put(this._store.modelRoot, params).then(
      (res) => {
        if (res && res["results"]) {
          this.set(res["results"]);
          this._store.items.addOrUpdateModel(this);
          this.endUpdate();
        }
      },
      (err) => {
        this.endUpdate(err);
        //throw err;
      }
    );
  }

  @action
  unArchive() {
    let params = {
      id: this.id,
      archived: false,
    };
    //make this a generic function
    this._store.appStore.coreAPIClient.put(this._store.modelRoot, params).then(
      (res) => {
        if (res && res["results"]) {
          this.set(res["results"]);
          this._store.items.addOrUpdateModel(this);
          this.endUpdate();
        }
      },
      (err) => {
        this.endUpdate(err);
        //throw err;
      }
    );
  }

  @action
  saveComment(comment) {
    let params = {
      id: this.id,
      comment: comment || this.comment,
    };
    this.beginUpdate();
    //make this a generic function
    this._store.appStore.coreAPIClient.put(this._store.modelRoot, params).then(
      (res) => {
        if (res && res["results"]) {
          this.set(res["results"]);
          this._store.items.addOrUpdateModel(this);
          this.endUpdate();
        }
      },
      (err) => {
        this.endUpdate(err);
        //throw err;
      }
    );

    return this;
  }

  @action
  pause() {
    this._store.appStore.orders.updateStatus(ON_HOLD, this.id, this);
  }

  @action
  resume() {
    let nextStatus = [READY_TO_SHIP, AWAITING_PAYMENT].includes(
      this.last_status
    )
      ? this.last_status
      : "new";

    return this._store.appStore.orders.updateStatus(nextStatus, this.id, this);
  }

  @action
  resume_async() {
    this._store.appStore.coreAPIClient.post("/orders/resume", {
      in_list: [this.id],
    });
  }

  @action
  cancel() {
    this._store.appStore.orders.updateStatus(CANCELLED, this.id, this);
  }

  @action
  ship() {
    if (this.status !== SHIPPED) {
      this._store.appStore.orders.updateStatus(SHIPPED, this.id, this);
    }
  }

  @action
  deliver() {
    if (this.status !== DELIVERED) {
      this._store.appStore.orders.updateStatus(DELIVERED, this.id, this);
    }
  }

  @action
  notDeliver() {
    if (this.status !== NOT_DELIVERED) {
      this._store.appStore.orders.updateStatus(NOT_DELIVERED, this.id, this);
    }
  }

  @action
  pay() {
    if (![NEW, READY_TO_PICK].includes(this.status)) {
      this._store.appStore.orders.updateStatus(NEW, this.id, this);
    }
  }

  @action
  setDeliveryDate(date) {
    let value =
      date && date.set
        ? date.set({
            h: 18,
            m: 0,
          })
        : "";

    if (!this.inFinalStatus() && this.status !== "shipped") {
      this.delivery_for = value !== "" && value.format("YYYY-MM-DDTHH:mm:ss");
    }
  }

  withStock() {
    return !this.withoutStock();
  }

  withoutStock() {
    const items = this.items.toJS();
    let result = false;
    items.forEach((i) => {
      if (i.quantity > i.stock.on_hand) result = true;
    });
    return result;
  }

  isMultiPackage() {
    const items = this.items.toJS();
    let result = false;
    items.forEach((i) => {
      if (i.package_number > 1) result = true;
    });
    return result;
  }

  toBePacking() {
    return (
      ["new", "on_hold", "awaiting_payment", "ready_to_pick"].includes(
        this.status
      ) && !this.timestamps.ready_to_ship_at
    );
  }

  isReturnAndExchangeAndReturnUpdateable() {
    return (
      this.relatedOrders &&
      this.relatedOrders[0] &&
      this.relatedOrders[0].exchangeAndReturn &&
      !this.relatedOrders[0].exchange &&
      !["shipped", "not_delivered", "delivered", "cancelled"].includes(
        this.relatedOrders[0].status
      )
    );
  }

  isExchangeAndReturnUpdateable() {
    return (
      this.exchangeAndReturn &&
      !this.exchange &&
      !["shipped", "not_delivered", "delivered", "cancelled"].includes(
        this.status
      )
    );
  }

  isResend() {
    return this.main_order_id && this.type === "resend";
  }

  onHoldByRelatedOrder() {
    return (
      !this.exchange &&
      !this.last_status &&
      (this.change_order_id || this.isResend())
    );
  }

  getWhodunitOf(status) {
    const logs = this.get("logs");

    if (!logs) return null;

    const currentUser = this._store.appStore.loggedInUser;

    const l = logs.find(
      (l) => l.changes.status && l.changes.status[1] === status
    );
    return {
      user:
        l && l.whodunit
          ? currentUser.isAdmin() ||
            currentUser.company.id === l.whodunit.user.account.id
            ? l.whodunit.user.description || l.whodunit.user.email
            : l.whodunit.user.account.company_name
          : null,
      changed_at: l && l.changed_at,
    };
  }

  @action
  addOrderItem(item, quantity) {
    let items = this.items;

    if (items.includes(item)) {
      return this;
    } else {
      this.beginUpdate();

      const newItem = {
        id: item.id,
        quantity: quantity,
        title: item.title,
        unit_price:
          item.unit_price ||
          (item.price ? item.price.unit_price || item.price.retail : null),
        stock: item.stock,
        thumbnail_url: item.thumbnail_url,
        external_reference_user: item.external_reference_user,
        external_reference: item.external_reference,
        dimensions: {
          weight:
            item.dimensions?.weight || item.declared_dimensions?.weight || 0,
          width: item.dimensions?.width || item.declared_dimensions?.width || 0,
          length:
            item.dimensions?.length || item.declared_dimensions?.length || 0,
          height:
            item.dimensions?.height || item.declared_dimensions?.height || 0,
        },
        package_number: item.package_number,
        variant_weight:
          item.variant_weight ||
          item.dimensions?.weight ||
          item.declared_dimensions?.weight ||
          0,
      };

      items.push(newItem);
      this.items.replace(items);
      this.recalculateWeight();
      this.endUpdate();
    }
  }

  @action
  removeOrderItem(item) {
    let items = this.items;
    if (!items.includes(item)) {
      return this;
    } else {
      this.beginUpdate();
      const newItemList = items.filter(
        (i) => !(i.id === item.id && i.package_number === item.package_number)
      );
      this.items.replace(newItemList);
      this.recalculateWeight();
      this.endUpdate();
    }
  }

  @action
  setData(data) {
    this.beginUpdate();
    if (data.type) {
      this.type = data.type;
    }
    this.buyerInfo["name"] = data.name;
    this.buyerInfo["last_name"] = data.last_name;
    this.buyerInfo["email"] = data.email;
    this.buyerInfo["phone"] = data.phone;
    this.buyerInfo["city"] = data.city;
    this.buyerInfo["state"] = data.state;
    this.buyerInfo["address_line"] = data.address_line;
    this.buyerInfo["zip_code"] = data.zip_code;
    this.buyerInfo["doc_type"] = data.doc_type;
    this.buyerInfo["doc_number"] = data.doc_number;
    this.buyerInfo["street_name"] = data.street_name;
    this.buyerInfo["street_number"] = data.street_number;
    this.buyerInfo["accuracy"] = data.accuracy;
    this.buyerInfo["lat"] = data.lat;
    this.buyerInfo["lon"] = data.lon;
    this.buyerInfo["floor"] = data.floor;
    this.buyerInfo["unit"] = data.unit;
    this.comment = data.comment;
    this.shipping_option["service_code"] = data.service_code;
    this.shipping_option["category"] = data.shipping_category;
    this.seller_id = data.seller_id;
    this.external_reference = data.external_reference;
    this.external_reference_user = data.external_reference_user;
    this.dispatch_document_url = data.dispatch_document_url;
    this.store_id = data.store_id;
    this.delivery_for = data.delivery_for;
    this.main_order_id = data.main_order_id;

    this.endUpdate();
  }

  isGoodsInItem() {
    return false;
  }

  createShipment() {
    return this._store.appStore.coreAPIClient.post(
      `${this.urlRoot}/${this.id}/shipments`,
      {
        seller_id: this.seller_id,
      }
    );
  }

  generateIntDate() {
    const date = new Date();

    return moment(date).format("DDMYYYYHmmss");
  }

  generateRelatedExternalReference() {
    return `${this.reference}-BIS-${this.generateIntDate()}`;
  }

  generateReturnExternalReference() {
    return `DEV-${this.generateIntDate()}`;
  }

  getReturnOrder() {
    const returnOrder = new Order({}, this._store);
    returnOrder.weight = this.weight;
    returnOrder._status = "ok";
    returnOrder.type = "exchange";
    returnOrder.items = this.items;

    returnOrder.shipping_option.service_type = "ship_sap";

    //? For to address returns, we automatically fill in the buyer's information.
    //? Otherwise, we only complete the personal information.
    if (this.isToAddress()) {
      returnOrder.ship_from = this.buyerInfo;
    } else {
      returnOrder.ship_from = {
        ...DEFAULT_SHIPPING_OBJ,
        ...pick(this.buyerInfo, BUYER_FIELDS),
      };
    }

    returnOrder.seller_id = this.seller_id;
    returnOrder.main_order_id = this.id;
    returnOrder.ship_to_warehouse_id = this.ship_from_warehouse_id;
    returnOrder.external_reference = this.generateReturnExternalReference();

    return returnOrder;
  }

  getRelatedOrder() {
    const relatedOrder = new Order({}, this._store);

    relatedOrder._status = "ok";
    relatedOrder.ship_to = this.buyerInfo;
    relatedOrder.seller_id = this.seller_id;
    relatedOrder.main_order_id = this.id;
    relatedOrder.shipping_option = this.shipping_option;
    relatedOrder.external_reference = this.generateRelatedExternalReference();
    relatedOrder.ship_from_warehouse_id = this.ship_from_warehouse_id;

    relatedOrder.items = this.items;

    return relatedOrder;
  }

  getChangeOrder() {
    const changeOrder = new Order({}, this._store);

    changeOrder._status = "ok";
    changeOrder.seller_id = this.seller_id;
    changeOrder.seller_name = this.seller_name;
    changeOrder.pointsOfSale = this.pointsOfSale;
    changeOrder.store = this.store;
    changeOrder.main_order_id = this.id;
    changeOrder.external_reference = `${this.reference}-BIS`;
    changeOrder.ship_from_warehouse_id = this.ship_from_warehouse_id;
    changeOrder.ship_to = Object.assign({}, this.ship_to);
    changeOrder.ship_to.address_line = null;
    changeOrder.ship_from = Object.assign({}, this.ship_from);

    if (this.isPAP) {
      changeOrder.shipping_option = this.shipping_option;
      changeOrder.shipping_option.service_code = null;
    }

    return changeOrder;
  }

  getChangeReturnOrder() {
    const returnOrder = new Order({}, this._store);
    returnOrder._status = "ok";
    returnOrder.type = "exchange";
    returnOrder.items = this.items.toJS();
    returnOrder.ship_from = Object.assign({}, this.ship_to);
    returnOrder.ship_from.address_line = null;
    returnOrder.ship_to = Object.assign({}, this.ship_from);
    returnOrder.seller_id = this.seller_id;
    returnOrder.shipping_option.service_type = null;
    returnOrder.seller_name = this.seller_name;
    returnOrder.pointsOfSale = this.pointsOfSale;
    returnOrder.store = this.store;
    returnOrder.main_order_id = this.id;
    returnOrder.ship_to_warehouse_id = this.ship_from_warehouse_id;
    returnOrder.external_reference = `DEV-${this.reference}`;

    return returnOrder;
  }

  @computed
  get isPAP() {
    return this.shipping_option.service_type === "ship_pap";
  }

  @computed
  get isPAS() {
    return this.shipping_option.service_type === "ship_pas";
  }

  @computed
  get isExchange() {
    return this.shipping_option.service_type === "exchange";
  }

  @computed
  get isSAP() {
    return this.shipping_option.service_type === "ship_sap";
  }

  @computed
  get isPickup() {
    return this.shipping_option.service_type === "pickup";
  }

  @computed
  get serviceType() {
    if (!this.shipping_option || !this.shipping_option.service_type)
      return undefined;

    return this.shipping_option.service_type;
  }

  @computed
  get serviceCode() {
    if (!this.shipping_option || !this.shipping_option.service_code)
      return undefined;

    return this.shipping_option.service_code;
  }

  isToAddress() {
    return this.isPAP || (this.isSAP && !this.isReturn());
  }

  isToPostOffice() {
    return this.isPAS || (this.isSAP && this.isReturn());
  }

  isToWarehouse() {
    return (
      this.serviceType === "pickup" ||
      (this.ship_from_warehouse_id &&
        this.ship_to_warehouse_id &&
        !this.isReturn()) ||
      (this.serviceCode === "exchange_warehouse" &&
        this.serviceType === "exchange")
    );
  }

  @computed
  get packageCount() {
    if (!this.items) return 0;

    return new Set(this.items.map((i) => i.package_number)).size;
  }

  @computed
  get totalItemsUnits() {
    return this.items
      .toJS()
      .filter((i) => !i.cross_docking)
      .reduce((totalItems, item) => parseInt(item.quantity) + totalItems, 0);
  }

  splitItemsIntoPackages(packageCount) {
    let index = 0;
    let packages = Array(parseInt(packageCount))
      .fill(null)
      .map(() => []);

    let items = this.items.toJS();

    items.forEach((item) => {
      for (let i = 0; item.quantity > i; i++) {
        let orderPackage = packages[index].find(
          (pack) => item.id === pack.variant_id
        );
        if (orderPackage) {
          ++orderPackage.quantity;
        } else {
          packages[index].push({
            variant_id: item.id,
            quantity: 1,
            weight: item.weight,
            type: item.package_type,
          });
        }

        index = (index + 1) % packageCount;
      }
    });

    let variant_id = items[0].id;

    packages.forEach((orderPackage) => {
      if (orderPackage.length === 0) {
        orderPackage.push({
          variant_id: variant_id,
          quantity: 1,
          weight: 0,
          type: null,
        });
      }
    });

    return packages;
  }

  generatePackages(packageCount) {
    let newPackages = [];

    let splitedItemsPackages = this.splitItemsIntoPackages(packageCount);

    const keepPrevValue = this.packageCount === splitedItemsPackages.length;

    for (let i = 0; i < packageCount; i++) {
      const { weight, type } = splitedItemsPackages[i][0];
      let items = [];
      splitedItemsPackages[i].forEach((sip) => {
        const { variant_id, quantity } = sip;
        items.push({ variant_id, quantity });
      });

      newPackages.push({
        weight: keepPrevValue ? weight : 0,
        items: items,
        number: i + 1,
        type: keepPrevValue ? type : "",
      });
    }

    this.packages = newPackages;

    return this;
  }

  //? Este método lo uso para reemplzar el método "generatePackages" cuando
  //? se quiere generar los packages con la información que llega del back.
  createPackages = () => {
    const { items } = this;

    let i;
    let packages = [];

    //? Se agrega el primer item dentro del primer paquete.
    let packageItems = [
      {
        quantity: items[0].quantity,
        variant_id: items[0].id,
      },
    ];

    for (i = 1; i < items.length; i++) {
      //? Si el item que estamos evaluando corresponde a otro paquete,
      //? guardamos el paquete anterior y reiniciamos la lista de items
      //? para agregar los items del siguiente paquete.
      if (items[i - 1].package_number !== items[i].package_number) {
        packages.push({
          items: packageItems,
          number: items[i - 1].package_number,
          weight: items[i - 1].weight / 1000,
        });

        packageItems = [];
      }

      packageItems.push({
        quantity: items[i].quantity,
        variant_id: items[i].id,
      });
    }

    //? Se agrega el último paquete a la lista de paquetes.
    packages.push({
      items: packageItems,
      number: items[i - 1].package_number,
      weight: items[i - 1].weight / 1000,
    });

    this.packages = packages;
  };

  async savePackages(params) {
    const packagesParams = params || this.packagesToParams();

    this.beginUpdate();

    try {
      const json = await this._store.appStore.coreAPIClient.post(
        `${this.urlRoot}/${this.id}/packages`,
        packagesParams,
        true,
        { fields: "account" }
      );

      this.set(json.results);

      this._store.items.addOrUpdateModel(
        new this._store.model(json.results, this._store)
      );

      this.endUpdate();
    } catch (e) {
      this.endUpdate(e);
      return Promise.reject(e);
    }
  }

  packagesToParams() {
    return {
      packages: this.packages.map((p) => toJS(p)),
    };
  }

  @action
  saveReturn() {
    return this.currentCheckIn.save();
  }

  @action
  saveCheckIn() {
    return this.currentCheckIn.save();
  }

  fetchReturnLabel() {
    if (!this.currentCheckIn) {
      return;
    }

    return this.currentCheckIn.fetchReturnLabel();
  }

  importInGoodsIn() {
    let filters = {
      any_reference: this.external_reference,
      seller_id: this.seller_id,
    };

    return new Promise((resolve, reject) => {
      this._store.search(filters, "default", true).andThen((res) => {
        if (res.isError()) {
          reject();
        } else {
          const orders = res.toArray();

          if (orders.length === 0) {
            this.save().andThen((res) => {
              if (res.isError()) {
                reject(res);
              } else {
                resolve(res);
              }
            });
          } else {
            resolve(orders);
          }
        }
      });
    });
  }

  haveTracking() {
    return (
      (this.shipping_service.carrier.code === "shipnow" ||
        this.shipping_service.carrier.code === "bmba") &&
      this.shipment &&
      this.shipment.external_tracking_url
    );
  }
  userReference() {
    return this.external_reference_user || this.external_reference;
  }

  @computed
  get itemsQuantity() {
    return (
      this.items &&
      this.items.reduce((accumulator, item) => item.quantity + accumulator, 0)
    );
  }

  @computed
  get variantsCount() {
    let countedItems = [];

    this.items.forEach((i) => {
      if (!countedItems.includes(i.id)) {
        countedItems.push(i.id);
      }
    });

    return countedItems.length;
  }

  @computed
  get cookedCarrierName() {
    switch (this.shipping_option.carrier_code) {
      case "pickup":
        return this.ship_from_warehouse.name;
      case "custom":
        return "Gestión Externa shipnow";
      default:
        if (this.carrier) {
          return this.carrier.name;
        } else {
          return this.shipment
            ? this.shipment.carrier.name
            : this.shipping_option.carrier_code;
        }
    }
  }

  @computed
  get cookedTrackingCarrierName() {
    if (
      !this.shipping_service ||
      (["shipped", "delivered", "not_delivered"].includes(this.status) &&
        !this.timestamps.ready_to_ship_at)
    )
      return undefined;
    if (
      (this.shipping_service &&
        this.shipping_service.carrier.name === "Pickup") ||
      this.shipping_service.code === "pickup"
    )
      return this.ship_from_warehouse.name;
    if (
      this.shipping_service &&
      ["Custom carrier", "Manual"].includes(this.shipping_service.carrier.name)
    )
      return "Gestión Externa shipnow";

    if (this.shipping_service && this.seller_id === 2151) return "TRF";
    return (
      this.shipping_service &&
      this.shipping_service.carrier &&
      this.shipping_service.carrier.name
    );
  }

  isNewStatus() {
    return this.status === "new";
  }
  isOnHold() {
    return this.status === "on_hold";
  }
  isAwaitingPayment() {
    return this.status === "awaiting_payment";
  }
  isReadyToPick() {
    return this.status === "ready_to_pick";
  }
  beforePackingStatus() {
    return (
      this.isNewStatus() || this.isAwaitingPayment() || this.isReadyToPick()
    );
  }

  inDispatchControl() {
    return (
      this.shipment &&
      this.shipment.items.some((obj) => {
        return obj.hasOwnProperty("dispatch_id") && obj.dispatch_id !== null;
      })
    );
  }

  isNotDelivered() {
    return this.type !== "exchange" && this.status === "not_delivered";
  }

  @computed
  get resendId() {
    if (this.check_in && this.check_in.action_resource_type === "Order") {
      return this.check_in.action_resource_id;
    }

    return null;
  }

  canCreateResendOrder(user) {
    const currentUser = this._store.appStore.loggedInUser;

    return (
      this.check_in.status === "created" ||
      (this.check_in.status === "received" && currentUser.isAdmin())
    );
  }

  //? this method indicates that it has a resend order associated.
  isCheckInResend() {
    return this.check_in && this.check_in.scheduled_action === "resend";
  }

  isCheckInFinalStatus() {
    return ["cancelled", "completed"].includes(this.check_in.status);
  }

  returnNeedProcess() {
    //? los pedidos de reentrega y no entregados no necesitan ser procesados
    return !(this.isCheckInResend() || this.isNotDelivered());
  }
  @computed
  get isOcaPAP() {
    return this.shipping_option.service_code === "oca_pap_exchange";
  }
}
